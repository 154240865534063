const WhatsApp = () => {
    return (
      <div className="d-print-none">
        <a  href="tel:+918447798447" className="btn-whatsapp-pulse skew-shake-y shadow">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="black" d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56a.98.98 0 0 0-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02c-.37-1.11-.56-2.3-.56-3.53c0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99C3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99"/></svg>
        </a>
        {/* <a  href="https://wa.link/8o4840" className="btn-whatsapp-pulse shadow">
          <i className="iconify" data-icon="akar-icons:whatsapp-fill" style={{ color: "white" }}></i>
        </a> */}
  
      </div>
    ) 
  };
  
  export default WhatsApp;